var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "system:termtemplate:list:button:edit",
      },
      on: {
        show: _vm.show,
        closed: _vm.closedModal,
        save: _vm.save,
        edit: _vm.editForm,
      },
    },
    [
      _c(
        "div",
        { staticClass: "info-group" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "名称" } },
            [_vm._v(_vm._s(_vm.curRow.name))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "类型" } },
            [_vm._v(_vm._s(_vm.curRow.type))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sort))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "说明" } },
            [_vm._v(_vm._s(_vm.curRow.description))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用部门" } },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap pt-1" },
                _vm._l(_vm.curRow.departments, function (item, index) {
                  return _c("a-tag", { key: index, staticClass: "mr-3 mb-3" }, [
                    _vm._v(_vm._s(item.departName)),
                  ])
                }),
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-wrap", attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c("g-title", [_vm._v("基本信息")]),
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "a-select",
                    {
                      ref: "select",
                      attrs: { placeholder: "请选择类型", allowClear: "" },
                      model: {
                        value: _vm.formData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "人物" } }, [
                        _vm._v("人物"),
                      ]),
                      _c("a-select-option", { attrs: { value: "团体" } }, [
                        _vm._v("非人物"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sort" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sort", $$v)
                      },
                      expression: "formData.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "说明", prop: "description" } },
                [
                  _c("a-textarea", {
                    attrs: { autoSize: "", placeholder: "请输入说明内容" },
                    model: {
                      value: _vm.formData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "description", $$v)
                      },
                      expression: "formData.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择部门", prop: "selectedDepart" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      treeDefaultExpandAll: "",
                      replaceFields: _vm.replaceFields,
                      "tree-checkable": "",
                      "tree-data": _vm.departList,
                      "search-placeholder": "选择部门",
                      placeholder: "请选择适用部门",
                    },
                    on: { change: _vm.changeDepartList },
                    model: {
                      value: _vm.selectedDeparts,
                      callback: function ($$v) {
                        _vm.selectedDeparts = $$v
                      },
                      expression: "selectedDeparts",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }