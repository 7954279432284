<template>
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    permission="system:termtemplate:list:button:edit"
    @show="show"
    @closed="closedModal"
    @save="save"
    @edit="editForm"
  >
    <div class="info-group">
      <g-title>基本信息</g-title>
      <info-item :label-width="labelWidth" label="名称">{{ curRow.name }}</info-item>
      <info-item :label-width="labelWidth" label="类型">{{ curRow.type }}</info-item>
      <info-item :label-width="labelWidth" label="序号">{{ curRow.sort }}</info-item>
      <info-item :label-width="labelWidth" label="说明">{{ curRow.description }}</info-item>
      <info-item :label-width="labelWidth" label="适用部门">
        <div class="flex flex-wrap pt-1">
          <a-tag v-for="(item, index) in curRow.departments" :key="index" class="mr-3 mb-3">{{
            item.departName
          }}</a-tag>
        </div>
      </info-item>
      <!--       <g-title>正文</g-title>
      <template v-if="curRow.cardList">
        <a-form v-for="one in curRow.cardList" :key="one.id">
          <a-form-item :label="one.type | typeFilter">
            {{ one.canAdd | canAddFilter }}
          </a-form-item>
          <a-form-item label="字段名称">
            {{ one.dataList[0].name }}
          </a-form-item>
          <a-form-item label="是否必填">
            {{ one.dataList ? (one.dataList[0].valueRequired == 'NO' ? '否' : '是') : '' }}
          </a-form-item>
          <a-form-item label="字段描述">
            {{ one.dataList ? one.dataList[0].description : '' }}
          </a-form-item>
          <a-form-item label="录入提示">
            {{ one.dataList ? one.dataList[0].placeholder : '' }}
          </a-form-item>
          <a-form-item v-if="one.type == 'INPUT_NUMBER'" label="小数位数">
            {{ one.dataList ? one.dataList[0].selectCount : '' }}
          </a-form-item>
          <a-form-item v-if="one.type == 'DYNAMIC_FORM'" label="显示方式">
            {{ one.viewMode == 'HORIZONTAL' ? '横排显示' : '竖排显示' }}
          </a-form-item>
          <a-form-item v-if="one.type == 'DYNAMIC_SELECT'">
            {{ one.additional == '1' ? '允许新增' : '不允许新增' }}
          </a-form-item>
          <template v-if="one.type == 'DYNAMIC_FORM' && one.dataList">
            <div v-for="(item, itemIndex) in one.dataList" :key="itemIndex" style="margin-bottom:-12px;">
              <a-form-item label="子选项">
                <span>{{ item.value }}</span>
                <span :style="item.value ? 'margin-left:20px;' : ''">{{
                  item.type == 'INPUT' ? '文本录入' : item.type == 'SELECT' ? '下拉选项' : '标签'
                }}</span>
              </a-form-item>
              <div :style="item.type != 'INPUT' ? 'margin-bottom:36px;margin-top:24px;' : 'height: 11px;'">
                <div style="margin-bottom:0px;">
                  <a-row>
                    <a-col v-if="item.type == 'TAG'" :span="16" style="margin-left:134px;padding-right:66px;">
                      <template v-for="(i, index) in item.options">
                        <a-tag style="margin-bottom:8px;" :key="index">{{ i.label }}</a-tag>
                      </template>
                    </a-col>
                    <a-col v-else :span="16" style="margin-left:134px;padding-right:66px;margin-top:-8px">
                      <div style="margin-bottom:5px;" v-for="(i, index) in item.options" :key="index">
                        <span class="ant-badge-status-dot ant-badge-status-processing"> </span>
                        <span>{{ i.label }}</span>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <a-form-item label="选项个数" v-if="item.options && item.type != 'RADIO' && item.type != 'SELECT'">
                {{ item.selectCount }}
              </a-form-item>
              <a-form-item v-if="item.type == 'DYNAMIC_SELECT'">
                {{ item.additional == '1' ? '允许新增' : '不允许新增' }}
              </a-form-item>
              <a-form-item v-if="item.type == 'DYNAMIC_SELECT'" label="设置数据源"> </a-form-item>
              <a-form-item v-if="item.type == 'DYNAMIC_SELECT'" label="显示类型"> </a-form-item>
            </div>
          </template>
          <a-form-item v-if="one.type == 'FILE_UPLOAD'" label="上传形式">
            {{ one.dataList[0].additional == '1' ? '本地上传' : '剪切板上传' }}
          </a-form-item>
          <a-form-item
            :class="
              one.type == 'SELECT' || one.type == 'RADIO' || one.type == 'FILE_UPLOAD'
                ? 'option-item'
                : 'option-item option-tag-item'
            "
            v-if="one.type == 'SELECT' || one.type == 'TAG' || one.type == 'RADIO' || one.type == 'FILE_UPLOAD'"
            :label="
              one.type == 'SELECT' || one.type == 'RADIO'
                ? '选项内容'
                : one.type == 'FILE_UPLOAD'
                ? '图片格式'
                : '标签内容'
            "
            style="padding-right: 66px;"
          >
            <template v-if="one.dataList">
              <template
                style="margin-bottom:5px;width:100%;line-height:24px;padding-right:66px;"
                v-for="(i, idx) in one.dataList[0].options"
              >
                <a-tag :key="idx" style="margin-bottom:8px;" v-if="one.type == 'TAG'">{{ i.label }}</a-tag>
                <div v-else :key="idx" :style="idx == 0 ? '' : 'margin-top:-15px;'">
                  <span class="ant-badge-status-dot ant-badge-status-processing"> </span>
                  <span>{{ i.label }}</span>
                </div>
              </template>
            </template>
          </a-form-item>
          <a-form-item v-if="one.type == 'FILE_UPLOAD' && one.dataList[0].attach == 'NO'" label="最大容量">
            {{ one.dataList[0].selectCount + 'MB' }}
          </a-form-item>
          <a-form-item v-if="one.type == 'FILE_UPLOAD'" label="是否裁剪">
            {{ one.dataList[0].attach == 'YES' ? '是' : '否' }}
          </a-form-item>
          <a-form-item v-if="one.type == 'FILE_UPLOAD'" label="裁剪形状">
            {{ one.dataList[0].extra == 'y' ? '圆形' : one.dataList[0].extra == 'c' ? '尺寸' : '比例' }}
          </a-form-item>
          <a-form-item
            v-if="one.type == 'FILE_UPLOAD' && one.dataList[0].extra == 'c' && one.dataList[0].attach == 'YES'"
            label="裁剪尺寸"
          >
            <span>宽度（PX）</span>
            <span style="margin-left:20px">{{ one.dataList[0].cum }}</span>
            <span style="margin-left:20px">高度（PX）</span>
            <span style="margin-left:20px">{{ one.dataList[0].other }}</span>
          </a-form-item>
          <a-form-item
            v-if="one.type == 'FILE_UPLOAD' && one.dataList[0].extra == 'b' && one.dataList[0].attach == 'YES'"
            label="裁剪比例"
          >
            <span>宽</span>
            <span style="margin-left:20px">{{ one.dataList[0].cum }}</span>
            <span style="margin-left:20px">高</span>
            <span style="margin-left:20px">{{ one.dataList[0].other }}</span>
          </a-form-item>
          <template
            v-if="
              one.type != 'INPUT_NUMBER' &&
                one.type != 'FILE_UPLOAD' &&
                one.type != 'DYNAMIC_SELECT' &&
                one.type != 'RADIO' &&
                one.type != 'DYNAMIC_FORM' &&
                one.type != 'SELECT'
            "
          >
            <a-form-item label="选项个数" v-if="one.type == 'SELECT' || one.type == 'TAG'">
              {{ one.dataList && one.dataList[0].selectCount }}
            </a-form-item>
            <a-form-item label="输入框字数" v-else>
              <div v-if="one.dataList[0].limitRange">
                {{ getLimitRange(one.dataList[0].limitRange) }}
              </div>
            </a-form-item>
          </template>
          <a-form-item v-if="one.type == 'DYNAMIC_SELECT'" label="设置数据源"> </a-form-item>
          <a-form-item v-if="one.type == 'DYNAMIC_SELECT'" label="显示类型"> </a-form-item>
        </a-form>
      </template> -->
    </div>
    <div class="form-wrap" slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <g-title>基本信息</g-title>

        <a-form-model-item label="名称" prop="name">
          <a-input v-model="formData.name" placeholder="请输入名称" />
        </a-form-model-item>
        <a-form-model-item label="类型" prop="type">
          <a-select ref="select" v-model="formData.type" placeholder="请选择类型" allowClear>
            <a-select-option value="人物">人物</a-select-option>
            <a-select-option value="团体">非人物</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="序号" prop="sort">
          <a-input v-model="formData.sort" placeholder="请输入序号" />
        </a-form-model-item>
        <a-form-model-item label="说明" prop="description">
          <a-textarea autoSize v-model="formData.description" placeholder="请输入说明内容" />
        </a-form-model-item>
        <a-form-model-item label="选择部门" prop="selectedDepart">
          <a-tree-select
            treeDefaultExpandAll
            v-model="selectedDeparts"
            :replaceFields="replaceFields"
            style="width: 100%"
            tree-checkable
            :tree-data="departList"
            search-placeholder="选择部门"
            placeholder="请选择适用部门"
            @change="changeDepartList"
          />
        </a-form-model-item>
        <!-- <a-form-model-item label="是否同步">
          <a-checkbox autoSize v-model="formData.syncData" />
        </a-form-model-item> -->
        <!-- <a-form-model-item label="适用部门" >
            <role-tree 
              :defaultDepartKeys="defaultDepartKeys" 
              @change="treeChange"
            />
          </a-form-model-item> -->

        <!--         <g-title>正文</g-title>
        <template v-if="formData.cardList">
          <template v-for="(one, index) in formData.cardList">
            <div class="edit-form" :key="one.id">
              <a-form-model-item :label="one.type | typeFilter" class="edit-form-remove">
                <a-checkbox v-model="one.canAdd" v-if="one.type == 'DYNAMIC_FORM'">
                  填写时是否允许动态加行
                </a-checkbox>
                <div v-else></div>
                <div class="card-remove">
                  <a-button @click="deleteCard(index)" style="position: relative" shape="circle" size="small" icon="close" />
                </div>
              </a-form-model-item>
              <a-form-item label="字段名称" class="description">
                <a-input v-model="one.dataList[0].name" placeholder="请输入" />
              </a-form-item>
              <a-form-item label="是否必填">
                <a-radio-group v-model="one.dataList[0].valueRequired">
                  <a-radio value="YES">是</a-radio>
                  <a-radio value="NO">否</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="字段描述" class="description">
                <a-input v-model="one.dataList[0].description" placeholder="请输入" />
              </a-form-item>
              <a-form-item label="录入提示" class="description">
                <a-input v-model="one.dataList[0].placeholder" placeholder="请输入" />
              </a-form-item>
              <a-form-item v-if="one.type=='INPUT_NUMBER'" label="小数位数" class="description">
                <a-input-number v-model="one.dataList[0].selectCount" :min="0" placeholder="请输入" />
              </a-form-item>
              <a-form-item v-if="one.type == 'DYNAMIC_FORM'" label="显示方式">
                <a-radio-group v-model="one.viewMode">
                  <a-radio value="HORIZONTAL">横排显示</a-radio>
                  <a-radio value="VERTICAL">竖排显示</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-checkbox @change='(e)=>{checkChange(e,one)}' v-if="one.type == 'DYNAMIC_SELECT'" style="margin-bottom:24px">
                填写时是否允许动态新增
              </a-checkbox>
              <template v-if="one.type == 'DYNAMIC_FORM'">
                <div v-for="(item, i) in one.dataList" :key="i">
                  <a-form-model-item class="dt-form" label="子选项">
                    <a-row>
                      <a-col style="width:100%;display:flex;flex:1;">
                        <a-input v-model="item.value" placeholder="请输入" />
                        <a-select ref="select" v-model="item.type" style="margin-left:10px;" @change="value => changeSelect(value, item)" allowClear>
                          <a-select-option value="INPUT">文本录入</a-select-option>
                          <a-select-option value="SELECT">下拉选项</a-select-option>
                          <a-select-option value="RADIO">横排单选</a-select-option>
                          <a-select-option value="TAG">标签</a-select-option>
                          <a-select-option value="DYNAMIC_SELECT">动态选择</a-select-option>
                          <a-select-option value="FILE_UPLOAD">图片上传</a-select-option>
                        </a-select>
                      </a-col>
                      <a-col style="min-width:45px;margin-left:10px;display: flex;justify-content: space-between;align-items: center;">
                        <a-button class="item-btn" shape="circle" size="small" icon="minus" @click="delItem(one.dataList, i)" />
                        <a-button style="margin-left:8px;" class="item-btn" shape="circle" size="small" icon="plus" @click="addItem(one.dataList, i)" />
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                  <template v-if="item.type=='SELECT'||item.type=='RADIO'">
                    <a-form-item label="选项内容">
                      <a-row>
                        <a-col style="flex:1;">
                          <a-input v-model="item.optionsPushValue" @change="
                            e => {
                              inputSearchChange(e, i, one.dataList)
                            }
                          " placeholder="请输入"></a-input>
                        </a-col>
                        <a-col style="margin-left:10px;min-width:45px;">
                          <a-button icon="plus" style="max-width: 56px;display: flex;justify-content: center;align-items: center;position: relative;top: 3px;" type="text" @click="addOptions(item.options, item.optionsPushValue, item, i, one.dataList)">添加</a-button>
                        </a-col>
                      </a-row>
                    </a-form-item>
                    <div style="margin-bottom:24px;" v-if="!item.isSeach">
                      <div class="tag-div" style="margin-bottom:0px;" v-for="(value, index) in item.options" :key="value.label + Math.random()">
                        <a-row>
                          <a-col :span="16" style="margin-left:96px;padding-right: 17px;margin-bottom:5px;">
                            <a-tag class="close-tag" closable @dblclick="
                              e => {
                                tagClick(e, value.label, index, item.options, i, one.dataList)
                              }
                            " @close="closeTag(index, item.options, i, one.dataList)">{{ value.label }}</a-tag>
                          </a-col>
                        </a-row>
                      </div>
                    </div>
                    <div style="margin-bottom:24px;" v-else>
                      <div class="tag-div" style="margin-bottom:0px;" v-for="(value, index) in item.options" :key="value.label + Math.random()">
                        <a-row>
                          <a-col :span="16" style="margin-left:96px;padding-right: 17px;margin-bottom:5px;">
                            <a-tag class="close-tag" closable v-if="isSeachItem(index)" @dblclick="
                              e => {
                                tagClick(e, value.label, index, item.options, i, one.dataList)
                              }
                            " @close="closeTag(index, item.options, i, one.dataList)">{{ value.label }}</a-tag>
                          </a-col>
                        </a-row>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.type=='TAG'">
                    <a-form-item label="标签">
                      <tag-drag-select style="margin-top:5px;" :isPm="true" :scope-id="i" @change="(tagList) => wordsFormChange(tagList,item.options,index,i)" :prop-data-list="item.options||[]"></tag-drag-select>
                    </a-form-item>
                  </template>
                  <template v-if="item.type == 'DYNAMIC_SELECT'">
                    <div>
                      <a-form-item label="设置数据源">
                        <a-select ref="select" mode="multiple" allowClear>
                        </a-select>
                      </a-form-item>
                      <a-form-item label="显示类型">
                        <a-select ref="select" allowClear>
                        </a-select>
                      </a-form-item>
                    </div>
                  </template>
                  <a-form-item label="选项个数" v-if="item.options&&item.type!='RADIO'&&item.type!='SELECT'" class="description">
                    <a-input-number :min="0" v-model="item.selectCount" />
                  </a-form-item>
                  <a-checkbox @change='(e)=>{checkChange(e,item)}' v-if="item.type == 'DYNAMIC_SELECT'" style="margin-bottom:24px">
                    填写时是否允许动态新增
                  </a-checkbox>
                  <a-form-item v-if="item.type == 'FILE_UPLOAD'" label="上传形式" class="description">
                    <a-radio-group v-model="item.additional">
                      <a-radio value="1">本地上传</a-radio>
                      <a-radio value="2">剪切板上传</a-radio>
                    </a-radio-group>
                  </a-form-item>
                  <template v-if="item.type == 'FILE_UPLOAD'">
                    <a-form-item label="图片格式">
                      <a-row>
                        <a-col style="flex:1;">
                          <a-input v-model="item.optionsPushValue" @change="
                            e => {
                              inputSearchChange(e, i, one.dataList)
                            }
                          " @blur="
                          e=>{inputBlur(e)}" placeholder="请输入"></a-input>
                          <div style="position: relative;margin-top:-1vh">
                            <div ref='visDiv' style="color:#f5222d;display:none;margin-left:5px;font-size:12px;position: absolute;top:0">
                              请输入正确图片格式!
                            </div>
                          </div>
                        </a-col>
                        <a-col style="margin-left:10px;min-width:45px;">
                          <a-button icon="plus" style="max-width: 56px;display: flex;justify-content: center;align-items: center;position: relative;top: 3px;" type="text" @click="addOptionsImg(item.options, item.optionsPushValue, item, i, one.dataList)">添加</a-button>
                        </a-col>
                      </a-row>
                    </a-form-item>
                    <div style="margin-bottom:24px;" v-if="!item.isSeach">
                      <div class="tag-div" style="margin-bottom:0px;" v-for="(value, index) in item.options" :key="value.label + Math.random()">
                        <a-row>
                          <a-col :span="16" style="margin-left:96px;padding-right: 17px;margin-bottom:5px;">
                            <a-tag class="close-tag" closable @dblclick="
                              e => {
                                tagClick(e, value.label, index, item.options, i, one.dataList)
                              }
                            " @close="closeTag(index, item.options, i, one.dataList)">{{ value.label }}</a-tag>
                          </a-col>
                        </a-row>
                      </div>
                    </div>
                    <div style="margin-bottom:24px;" v-else>
                      <div class="tag-div" style="margin-bottom:0px;" v-for="(value, index) in item.options" :key="value.label + Math.random()">
                        <a-row>
                          <a-col :span="16" style="margin-left:96px;padding-right: 17px;margin-bottom:5px;">
                            <a-tag class="close-tag" closable v-if="isSeachItem(index)" @dblclick="
                              e => {
                                tagClick(e, value.label, index, item.options, i, one.dataList)
                              }
                            " @close="closeTag(index, item.options, i, one.dataList)">{{ value.label }}</a-tag>
                          </a-col>
                        </a-row>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.type == 'FILE_UPLOAD'">
                    <div>
                      <a-form-item label="是否裁剪" class="description">
                        <a-radio-group v-model="item.attach">
                          <a-radio value="YES">是</a-radio>
                          <a-radio value="NO">否</a-radio>
                        </a-radio-group>
                      </a-form-item>
                      <a-form-item v-if="!item.attach" label="最大容量（MB）" class="description">
                        <a-input-number v-model="item.description" :min="0" placeholder="请输入" />
                      </a-form-item>
                      <a-form-item v-if="item.cj" label="裁剪形状" class="description">
                        <a-select ref="select" v-model="item.xz" allowClear>
                          <a-select-option value="y">圆形</a-select-option>
                          <a-select-option value="c">尺寸</a-select-option>
                          <a-select-option value="b">比例</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item v-if="item.xz=='c'&&item.cj" label="裁剪尺寸" class="description-two">
                        <div style="display:flex;align-items: center;width:48%;"><span style="width:180px">宽度（PX）</span>
                          <a-input-number v-model="item.description" :min="0" placeholder="请输入" />
                        </div>
                        <div style="display:flex;align-items: center;width:48%;"><span style="width:180px">高度（PX）</span>
                          <a-input-number v-model="item.description" :min="0" placeholder="请输入" />
                        </div>
                      </a-form-item>
                      <a-form-item v-if="item.xz=='b'&&oitem.cj" label="固定比例" class="description-two">
                        <div style="display:flex;align-items: center;width:48%;"><span style="width:180px">宽</span>
                          <a-input-number v-model="item.description" :min="0" placeholder="请输入" />
                        </div>
                        <div style="display:flex;align-items: center;width:48%;"><span style="width:180px">高</span>
                          <a-input-number v-model="item.description" :min="0" placeholder="请输入" />
                        </div>
                      </a-form-item>
                    </div>
                  </template>
                </div>
              </template>
              <template v-if="one.type == 'SELECT'||one.type=='RADIO'">
                <a-form-item label="选项内容">
                  <a-row type="flex">
                    <a-col style="flex:1">
                      <a-input v-model="one.dataList[0].optionsPushValue" placeholder="请输入"> </a-input>
                    </a-col>
                    <a-col>
                      <a-button icon="plus" style="max-width: 56px;display: flex;justify-content: center;align-items: center;position: relative;top: 3px;" type="text" @click="
                        addOptions(
                          one.dataList[0].options,
                          one.dataList[0].optionsPushValue,
                          one.dataList[0],
                          0,
                          one.dataList
                        )
                      ">添加
                      </a-button>
                    </a-col>
                  </a-row>
                </a-form-item>
                <div style="margin-bottom:24px;" v-if="!one.dataList[0].isSeach">
                  <div class="tag-div" style="margin-bottom:0px;" v-for="(value, index) in one.dataList[0].options" :key="value.label + Math.random()">
                    <a-row>
                      <a-col :span="16" style="margin-left:96px;padding-right: 17px;margin-bottom:5px;">
                        <a-tag class="close-tag" closable @dblclick="
                          e => {
                            tagClick(e, value.label, index, one.dataList[0].options, 0, one.dataList)
                          }
                        " @close="closeTag(index, one.dataList[0].options, 0, one.dataList)">{{ value.label }}</a-tag>
                      </a-col>
                    </a-row>
                  </div>
                </div>
                <div style="margin-bottom:24px;" v-else>
                  <div class="tag-div" style="margin-bottom:0px;" v-for="(value, index) in one.dataList[0].options" :key="value.label + Math.random()">
                    <a-row>
                      <a-col :span="16" style="margin-left:96px;padding-right: 17px;margin-bottom:5px;">
                        <a-tag class="close-tag" closable v-if="isSeachItem(index)" @dblclick="
                          e => {
                            tagClick(e, value.label, index, one.dataList[0].options, 0, one.dataList)
                          }
                        " @close="() => closeTag(index, one.dataList[0].options, 0, one.dataList)">{{ value.label }}</a-tag>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </template>
              <a-form-item v-if="one.type == 'TAG'" label="标签内容">
                <tag-drag-select :isPm="true" :scope-id="0" @change="(tagList) => wordsChange(tagList,one.dataList[0].options,index)" :prop-data-list="one.dataList[0].options||[]"></tag-drag-select>
              </a-form-item>
              <a-form-item v-if="one.type!='FILE_UPLOAD'&&one.type!='DYNAMIC_SELECT'&&one.type!='RADIO'&&one.type!='DYNAMIC_FORM'&&one.type!='SELECT'" :label="one.type == 'SELECT'||one.type == 'TAG'?'选项个数':'输入框字数'" class="description">
                <a-input-number v-if="one.type == 'SELECT'||one.type == 'TAG'" :min="0" v-model="one.dataList[0].selectCount" />
                <edit-range-select v-else :formData="one.dataList[0].limitRange" :rangeKey="'type'" :valueKey="'value'" :needUnit="false" />
              </a-form-item>
              <a-form-item v-if="one.type == 'DYNAMIC_SELECT'" label="设置数据源" class="description">
                <a-select ref="select" mode="multiple" allowClear>
                </a-select>
              </a-form-item>
              <a-form-item v-if="one.type == 'DYNAMIC_SELECT'" label="显示类型" class="description">
                <a-select ref="select" allowClear>
                </a-select>
              </a-form-item>
              <a-form-item v-if="one.type == 'FILE_UPLOAD'" label="上传形式" class="description">
                <a-radio-group v-model="one.dataList[0].additional">
                  <a-radio value="1">本地上传</a-radio>
                  <a-radio value="2">剪切板上传</a-radio>
                </a-radio-group>
              </a-form-item>
              <template v-if="one.type == 'FILE_UPLOAD'">
                <a-form-item label="图片格式">
                  <a-row type="flex">
                    <a-col style="flex:1">
                      <a-input v-model="one.dataList[0].optionsPushValue" @blur="inputBlur" placeholder="请输入" style="position: relative;"> </a-input>
                      <div style="position: relative;margin-top:-1vh">
                        <div ref='visDiv' style="color:#f5222d;display:none;margin-left:5px;font-size:12px;position: absolute;top:0;">
                          请输入正确图片格式!
                        </div>
                      </div>
                    </a-col>
                    <a-col>
                      <a-button icon="plus" style="max-width: 56px;display: flex;justify-content: center;align-items: center;position: relative;top: 3px;" type="text" @click="
                        addOptionsImg(
                          one.dataList[0].options,
                          one.dataList[0].optionsPushValue,
                          one.dataList[0],
                          0,
                          one.dataList
                        )
                      ">添加
                      </a-button>
                    </a-col>
                  </a-row>
                </a-form-item>
                <div style="margin-bottom:24px;" v-if="!one.dataList[0].isSeach">
                  <div class="tag-div" style="margin-bottom:0px;" v-for="(value, index) in one.dataList[0].options" :key="value.label + Math.random()">
                    <a-row>
                      <a-col :span="16" style="margin-left:96px;padding-right: 17px;margin-bottom:5px;">
                        <a-tag class="close-tag" closable @dblclick="
                          e => {
                            tagClick(e, value.label, index, one.dataList[0].options, 0, one.dataList)
                          }
                        " @close="closeTag(index, one.dataList[0].options, 0, one.dataList)">{{ value.label }}</a-tag>
                      </a-col>
                    </a-row>
                  </div>
                </div>
                <div style="margin-bottom:24px;" v-else>
                  <div class="tag-div" style="margin-bottom:0px;" v-for="(value, index) in one.dataList[0].options" :key="value.label + Math.random()">
                    <a-row>
                      <a-col :span="16" style="margin-left:96px;padding-right: 17px;margin-bottom:5px;">
                        <a-tag class="close-tag" closable v-if="isSeachItem(index)" @dblclick="
                          e => {
                            tagClick(e, value.label, index, one.dataList[0].options, 0, one.dataList)
                          }
                        " @close="() => closeTag(index, one.dataList[0].options, 0, one.dataList)">{{ value.label }}</a-tag>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </template>
              <a-form-item v-if="one.type=='FILE_UPLOAD'&&one.dataList[0].attach=='NO'" label="最大容量（MB）" class="description">
                <a-input-number v-model="one.dataList[0].selectCount" :min="0" placeholder="请输入" />
              </a-form-item>
              <a-form-item v-if="one.type == 'FILE_UPLOAD'" label="是否裁剪" class="description">
                <a-radio-group v-model="one.dataList[0].attach">
                  <a-radio value="YES">是</a-radio>
                  <a-radio value="NO">否</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item v-if="one.type == 'FILE_UPLOAD'&&one.dataList[0].attach=='YES'" label="裁剪形状" class="description">
                <a-select ref="select" v-model="one.dataList[0].extra" @change="extraChange(one.dataList[0])" allowClear>
                  <a-select-option value="y">圆形</a-select-option>
                  <a-select-option value="c">尺寸</a-select-option>
                  <a-select-option value="b">比例</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-if="one.type == 'FILE_UPLOAD'&&one.dataList[0].extra=='c'&&one.dataList[0].attach=='YES'" label="裁剪尺寸" class="description-two">
                <div style="display:flex;align-items: center;width:48%;"><span style="width:180px">宽度（PX）</span>
                  <a-input-number v-model="one.dataList[0].cum" :min="0" placeholder="请输入" />
                </div>
                <div style="display:flex;align-items: center;width:48%;"><span style="width:180px">高度（PX）</span>
                  <a-input-number v-model="one.dataList[0].other" :min="0" placeholder="请输入" />
                </div>
              </a-form-item>
              <a-form-item v-if="one.type == 'FILE_UPLOAD'&&one.dataList[0].extra=='b'&&one.dataList[0].attach=='YES'" label="固定比例" class="description-two">
                <div style="display:flex;align-items: center;width:48%;"><span style="width:50px">宽</span>
                  <a-input-number v-model="one.dataList[0].cum" :min="0" placeholder="请输入" />
                </div>
                <div style="display:flex;align-items: center;width:48%;"><span style="width:50px">高</span>
                  <a-input-number v-model="one.dataList[0].other" :min="0" placeholder="请输入" />
                </div>
              </a-form-item>
            </div>
          </template>
        </template> -->
        <!--         <a-form-model-item class="add-item-form-item">
          <a-select placeholder="请选择组件" value="请选择组件" @change="formSelectChange">
            <a-select-option
              v-for="item in componentList"
              :key="item.cardList[0].type"
              :value="item.cardList[0].type"
              >{{ item.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item> -->
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import draggable from 'vuedraggable'
import { getAction, postAction, putAction } from '@/api/manage'
import EditRangeSelect from '@/components/edit/edit-range-select.vue'
import DynamicCardItem from '@/components/dynamic-card-item'
import DynamicFormItem from '@/components/dynamic-form-item'
import TagDragSelect from '@/components/TagDragSelect'
import { queryIdTree, ajaxGetDictItems } from '@/api/api'
import RoleTree from '@/views/system/components/RoleTree'
const defaultFields = {
  fieldName: '',
  fieldClassId: '',
  isRequired: false,
  isKeyword: false
}
export default {
  mixins: [DrawerMixins],
  // props: {
  //   show: {
  //     type: Boolean,
  //     default: false
  //   },
  //   isNew: {
  //     type: Boolean,
  //     default: false
  //   },
  //   edit: {
  //     type: Boolean,
  //     default: false
  //   },
  //   id: [Number,String]
  // },
  components: {
    // RoleTree,
    draggable,
    DynamicCardItem,
    DynamicFormItem,
    TagDragSelect,
    EditRangeSelect
  },
  watch: {},
  filters: {
    isSeachItem(index) {
      return this.searchIndex.some((e, i) => {
        return e == index
      })
    },
    typeFilter(data) {
      const typeMap = {
        DYNAMIC_FORM: '动态表单',
        INPUT: '单行文本',
        INPUT_NUMBER: '数字输入框',
        TEXTAREA: '多行文本',
        RADIO: '单项选择',
        SELECT: '下拉选择',
        TAG: '标签',
        FILE_UPLOAD: '图片上传',
        DYNAMIC_SELECT: '动态选择'
      }
      return typeMap[data]
    },
    canAddFilter(canAdd) {
      const canAddMap = {
        true: '允许加行',
        false: '不允许加行',
        null: ''
      }
      return canAddMap[canAdd]
    }
  },
  data() {
    return {
      rules: {
        description: [{ required: true, message: '请输入说明内容', trigger: 'blur' }],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }]
      },
      url: {
        /* edit: '/productDetail', */
        edit: '/term/type/edit',
        add: '/term/type/edit'
      },
      replaceFields: {
        value: 'orgCode',
        key: 'orgCode'
      },
      labelWidth: '80',
      layout: {
        labelCol: {
          style: 'width: 80px',
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      typesList: [],
      typeTitleList: [],
      componentList: [],
      imgFormat: [
        'bmp',
        'jpg',
        'png',
        'tif',
        'gif',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'psd',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'wmf',
        'webp',
        'avif',
        'apng',
        'BMP',
        'JPG',
        'PNG',
        'TIF',
        'GIF',
        'PCX',
        'TGA',
        'EXIF',
        'FPX',
        'SVG',
        'PSD',
        'CDR',
        'PCD',
        'DXF',
        'UFO',
        'EPS',
        'AI',
        'ROW',
        'WMF',
        'WEBP',
        'AVIF',
        'APNG'
      ]
    }
  },
  methods: {
    save() {
      const self = this
      this.setFormData()
      console.log('保存...')
      this.btnLoading = true
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (self.isEdit || self.isEditForm) {
            self.formData.platformId = self.curRow.platformId
            postAction(self.url.edit, self.formData).then(async res => {
              if (res.success) {
                if (
                  self.inspectSimpleValue == 'inspectSimpleBlack' ||
                  self.inspectSimpleValue == 'inspectSimpleFilter'
                ) {
                  const response = await postAction('/qualityInspection/blackWhiteSync', {})
                  if (response.data.code != 0) {
                    self.$message.error(response.data)
                  }
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.btnLoading = false
                self.closed()
              } else {
                self.btnLoading = false
                self.$message.error(res.message || res.msg)
              }
            })
          } else {
            console.log('新建URL', self.url.add)
            console.log('新建参数', self.formData)
            postAction(self.url.add, self.formData).then(async res => {
              if (res.success) {
                if (
                  self.inspectSimpleValue == 'inspectSimpleBlack' ||
                  self.inspectSimpleValue == 'inspectSimpleFilter'
                ) {
                  const response = await postAction('/qualityInspection/blackWhiteSync', {})
                  if (response.data.code != 0) {
                    self.$message.error(response.message)
                  }
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.btnLoading = false
                self.closed()
                self.closedModel()
              } else {
                self.btnLoading = false
                self.$message.error(res.message || res.msg)
              }
            })
          }
        } else {
          self.btnLoading = false
        }
      })
    },
    checkChange(e, data) {
      if (e.target.checked) {
        data.additional = '1'
      } else {
        data.additional = '2'
      }
    },
    addOptionsImg(options, optionsPushValue, i, index, dataList) {
      if (!this.imgFormat.some(item => item == optionsPushValue)) return
      this.isReturn = false
      if (options) {
        options.forEach(item => {
          if (item.value == optionsPushValue.toUpperCase()) this.isReturn = true
          if (item.value == optionsPushValue.toLowerCase()) this.isReturn = true
        })
        if (this.isReturn) return
      }
      if (optionsPushValue) {
        if (!options) {
          i.options = []
          options = i.options
        }
        let item = Object.assign({}, { value: optionsPushValue, label: optionsPushValue })
        if (
          !options.some(option => {
            return option.value == item.value
          })
        )
          options.push(item)
        i.optionsPushValue = ''
        dataList[index].isSeach = false
      }
    },
    addOptions(options, optionsPushValue, i, index, dataList) {
      if (optionsPushValue) {
        if (!options) {
          i.options = []
          options = i.options
        }
        let item = Object.assign({}, { value: optionsPushValue, label: optionsPushValue })
        if (
          !options.some(option => {
            return option.value == item.value
          })
        )
          options.push(item)
        i.optionsPushValue = ''
        dataList[index].isSeach = false
      }
    },
    deleteCard(index) {
      this.formData.cardList.splice(index, 1)
    },
    extraChange(data) {
      delete data.cum
      delete data.other
    },
    closeTag(index, options, i, dataList) {
      this.$nextTick(() => {
        options.splice(index, 1)
        console.log(dataList[i])
        if (dataList[i].isSeach) dataList[i].isSeach = false
      })
    },
    tagClick(e, label, index, options, i, dataList) {
      /* this.tooltipValue=value */
      const _this = this
      e.target.style.borderColor = '#5389DF'
      e.target.innerHTML = `<input placeholder='请输入' style='box-sizing: border-box;border:none;background-color: #fafafa;width:100%;height:20px;padding-left:0' /><i aria-label='图标: close' tabindex='-1' class='anticon anticon-close'><svg viewBox='64 64 896 896' data-icon='close' width='1em' height='1em' fill='currentColor' aria-hidden='true' focusable='false' class=''><path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path></svg></i>`
      e.target.childNodes[0].value = label
      e.target.childNodes[0].onmouseover = function() {
        this.style.outline = 'none'
      }
      e.target.childNodes[1].onclick = function() {
        return _this.closeTag(index, options, i, dataList)
      }
      e.target.childNodes[0].onkeyup = function(e) {
        if (e.keyCode == 13) {
          let text = e.target.value.trim()
          e.target.value = text
          options[index].label = e.target.value
          options[index].value = e.target.value
          _this.isSeach = false
        }
      }
      e.target.childNodes[0].onblur = function(e) {
        let text = e.target.value.trim()
        e.target.value = text
        options[index].label = e.target.value
        options[index].value = e.target.value
        if (!options[index].value) options.splice(index, 1)
        _this.isSeach = false
      }
      e.target.childNodes[0].focus()
    },
    wordsChange(tagList, options, index) {
      let arr = []
      tagList.forEach(tag => {
        let item = { value: tag.value, label: tag.value }
        arr.push(item)
      })
      this.formData.cardList[index].dataList[0].options = [...arr]
      console.log(this.formData, 'options')
    },
    inputBlur(e) {
      this.$nextTick(() => {
        if (!this.imgFormat.some(item => item == e.target.value)) {
          e.target.style.borderColor = '#f5222d'
          this.$refs.visDiv[0].style.display = 'block'
        } else {
          e.target.style.borderColor = '#d9d9d9'
          this.$refs.visDiv[0].style.display = 'none'
        }
      })
    },
    inputSearchChange(e, index, dataList) {
      if (!e.target.value) dataList[index].isSeach = false
    },
    delItem(dataList, index) {
      dataList.splice(index, 1)
    },
    addItem(dataList, index) {
      dataList.splice(index + 1, 0, { value: '', type: '', options: null })
    },
    changeSelect(value, item) {
      if (value === 'SELECT' || value === 'TAG') {
        item.options = []
      } else {
        delete item.options
      }
      if (value === 'FILE_UPLOAD') {
        item = Object.assign({}, item, { cj: null })
      } else {
        delete item.cj
      }
    },
    async getComponents() {
      const res = await getAction('/productDetail/page', { size: -1, domain: 'COMPONENTS', type: 'GLOBAL' })
      try {
        this.componentList = res.data.records
      } catch (err) {
        this.$message.error(res.msg || res.message)
      }
    },
    formSelectChange(value) {
      let item = {
        type: value,
        viewMode: 'HORIZONTAL',
        canAdd: null,
        dataList: [
          {
            valueRequired: '',
            options: null,
            isSeach: false,
            valueRequired: 'NO',
            value: ''
          }
        ]
      }
      this.componentList.forEach(com => {
        com.cardList.forEach(card => {
          if (card.type == value) {
            item = card
          }
        })
      })
      let cardList = this.formData.cardList || []
      cardList.push(item)
      this.formData = Object.assign({}, this.formData, { cardList })
    },
    getLimitRange(limitRange) {
      let obj = { ...limitRange }
      switch (obj.type) {
        case 'GT':
          return obj.value ? '>' + obj.value : ''
        case 'LT':
          return obj.value ? '<' + obj.value : ''
        case 'BETWEEN':
          return obj.start ? obj.start + '~' + obj.end : ''
      }
    },
    wordsFormChange(tagList, options, index, i) {
      let arr = []
      tagList.forEach(tag => {
        let item = { value: tag.value, label: tag.value }
        arr.push(item)
      })
      this.formData.cardList[index].dataList[i].options = [...arr]
    },
    closedModal() {
      this.closed()
    },
    editForm() {
      this.edit()
      this.insertForm()
    },
    initForm() {
      this.formData = {
        sort: '',
        description: '',
        title: '',
        departments: []
      }
      this.selectedDeparts = []
    },

    show() {
      this.getDepartList()
      this.getComponents()
      this.getDicts()
      if (this.isEdit) {
        this.insertForm()
      } else {
        this.initForm()
      }
    },
    getDepartList() {
      queryIdTree().then(({ data }) => {
        this.departList = data
        this.companyList = JSON.parse(JSON.stringify(data))
        this.companyList.forEach(one => {
          one.children.forEach(two => {
            two.children = []
          })
        })
      })
    },
    insertForm() {
      console.log(this.curRow,123)
      this.formData = this.curRow
      const { departCode } = this.formData
      this.selectedDeparts = departCode.split(',')
      console.log(this.selectedDeparts)
    },
    setFormData() {
      this.formData.departCode=this.selectedDeparts.join(',')
      if (!this.isEdit) delete this.formData.id
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0
    },

    addField() {
      this.formData.fields.push(Object.assign({}, defaultFields))
    },
    removeField(index) {
      if (this.formData.fields.length <= 1) return
      this.formData.fields.splice(index, 1)
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
/deep/ .option-tag-item {
  .ant-form-item-children {
    display: flex;
    flex-wrap: wrap;
    margin-top: 13px;
  }
}
/deep/ .ant-badge-status-processing {
  background-color: rgba(0, 0, 0, 0.65);
  margin-right: 3px;
  &::after {
    border: none;
  }
}
/deep/ .button-group {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  margin-bottom: 10px;
  .ant-btn {
    margin-right: 0;
  }
}
/deep/ .tag-drag-select {
  .ul {
    padding: 10px 20px;
    .li {
      line-height: 22px;
      margin: 10px 0 0 0;
    }
  }
}
/deep/ .description {
  .ant-form-item-control-wrapper {
    padding-right: 66px;
  }
  .ant-form-item-control {
    margin-top: 2px;
  }
}
/deep/ .dt-form {
  .ant-form-item-control {
    margin-top: 4px;
  }
}
/deep/ .option-item {
  .ant-form-item-control-wrapper {
    width: 66%;
    .ant-form-item-control {
      width: 100%;
      .ant-form-item-children {
        width: 100%;
      }
    }
  }
}

/deep/ .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 4px;
}

/deep/ .first-form-item {
  label {
    font-weight: 400;
  }
}

/deep/ .tag-div .ant-tag {
  width: 100%;
  white-space: pre-wrap;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/ .edit-form-remove .ant-form-item-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
/deep/ .edit-form-remove .ant-form-item-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  .ant-form-item-control {
    width: 100%;
  }
}
.card-remove {
  display: flex;
  align-items: center;
  width: 58px;
  justify-content: end;
}

.add-item-form-item {
  width: 120px;

  /deep/ .ant-col {
    width: inherit;
  }
}

.ant-form > .ant-form-item:first-child {
  /deep/ .ant-form-item-label {
    font-weight: 900;
  }
}

.edit-form > .ant-form-item:first-child {
  /deep/ .ant-form-item-label {
    font-weight: 900;
  }
}

/deep/ .ant-form-item {
  max-width: 100%;
}

/deep/ .ant-form-item-label {
  width: 120px;
  margin-right: 16px;
}

/deep/ .ant-row {
  display: flex;
}

.dynamic-card-prop-item {
  width: 100%;
}

/deep/ .ant-form-item-children {
  .ant-row-flex {
    & > .ant-col {
      & > * {
        margin-left: 10px;
      }

      &:first-child > * {
        margin-left: 0px;
      }
    }
  }
}
/deep/ .description-two {
  .ant-form-item-control-wrapper {
    padding-right: 17px;
  }
  .ant-form-item-control {
    margin-top: 2px;
  }
  .ant-form-item-children {
    display: flex;
    justify-content: space-between;
  }
}
</style>
